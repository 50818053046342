<template>
  <v-row class="ml-md-0 pt-5">
    <h2 class="mb-0 secondary--text font-weight-bold">{{ $t("signIn") }}</h2>
    <span>{{ $t("enterEmailPassword") }}</span>
    <v-col cols="12">
      <custom-text-field
        :customLabel="$t('emailAddress')"
        :hide-details="emailError.length === 0"
        :error-count="emailError.length"
        :error-messages="emailError"
        v-model="email"
        required
        @keydown.enter="onSubmit"
        color="main_text_color"
        />
    </v-col>
    <v-col cols="12 relative">
      <custom-text-field
        :customLabel="$t('password')"
        required
        @keydown.enter="onSubmit"
        customType="password"
        :hide-details="errors.length === 0"
        color="main_text_color"
        :error-count="errors.length"
        :error-messages="errors"
        v-model="password"
        />
    </v-col>
    <v-col cols="12">
      <router-link
        :to="{ name: 'auth1.password-reset' }"
        class="float-end secondary--text"
      >
        {{ $t("forgotPassword") }} ?
      </router-link>
    </v-col>
    <v-col cols="12">
        <save-button :save="onSubmit" :loading="loading" :message="$t('signIn')" cssClasses="w-100 p-4 border-radius-small" color="secondary"/>
    </v-col>
    <v-col col="12">
      <social-action-button :onClick="loginGoogle" socialName="google" :text="$t('loginGoogle')" />
    </v-col>
    <v-col cols="12">
      <social-action-button :onClick="loginFacebook" socialName="facebook" :text="$t('loginFacebook')" />
    </v-col>
    <v-col
      cols="12"
      class="text-center"
    >
      <span class="dark-color d-inline-block line-height-2"
        >{{ $t("dontHaveAccount") }} ?
        <router-link :to="{ name: 'auth1.sign-up1' }" class="secondary--text">{{
          $t("signUp")
        }}</router-link>
      </span>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import moment from 'moment/moment';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import userDataMixin from '@/mixins/userDataMixin';
import SocialActionButton from '@/components/socialvue/buttons/SocialActionButton.vue';
import CustomTextField from '@/components/socialvue/customTextField/CustomTextField.vue';

export default {
  name: 'SignIn1',
  components: { SaveButton, SocialActionButton, CustomTextField },
  mixins: [errorDialog, userDataMixin],
  data () {
    return {
      errors: [],
      emailError: [],
      loading: false,
      email: '',
      query: {},
      password: '',
      showPassword: false,
      user: {}
    };
  },
  created () {
    if (this.$route.params.query) {
      this.query = JSON.parse(this.$route.params.query);
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    createFreeSubscription () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/orders`;
      const newDate = moment();
      const now = moment(moment.utc(newDate)).format('DD-MM-YYYY');
      const formData = {
        subscription_id: 1,
        user_id: this.query.userId,
        status: 20,
        price: 0,
        payment_date: now
      };
      axios
        .post(url, formData)
        .then(() => {
          this.$swal.fire({
            title: this.$t('awesome'),
            text: this.$t('successfulFreePlanSubscription'),
            confirmButtonColor: '#19AD93'
          });
          this.$router.push({ name: 'social.list' });
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    onShowPassword () {
      this.showPassword = !this.showPassword;
    },
    loginGoogle () {
      window.location.href = process.env.VUE_APP_BASE_URL + '/login/google';
    },
    loginFacebook () {
      window.location.href = process.env.VUE_APP_BASE_URL + '/login/facebook';
    },
    validEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    resendVerifyEmail () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/user/verification/resend`;
      const data = {
        email: this.email
      };
      axios
        .post(url, data)
        .then(() => {})
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
    accountNotVerified (error) {
      return error.response.data.errors && error.response.data.errors.login && error.response.data.errors.login.find(item => item === 'userHasNotBeenVerified');
    },
    onSubmit () {
      this.errors = [];
      this.emailError = [];
      const passwordValidation = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\-_.;,\\])[A-Za-z\d@$!%*#?&\-_.;,\\]{8,}$/;
      if (this.email === '') {
        this.emailError.push(this.$t('emailRequired'));
      }
      if (!this.validEmail(this.email)) {
        this.emailError.push(this.$t('enterValidEmail'));
      }
      if (this.password === '') {
        this.errors.push(this.$t('passwordRequired'));
      }
      if (!this.password.match(passwordValidation)) {
        this.errors.push(this.$t('invalidPassword'));
      }
      if (
        this.password.match(passwordValidation) &&
        this.password !== '' &&
        this.email !== '' &&
        this.validEmail(this.email)
      ) {
        this.loading = true;
        const user = {
          email: this.email,
          password: this.password
        };
        this.signIn(user)
          .then(() => {
            this.loading = false;
            if (this.query.isFreeSubscription) {
              this.createFreeSubscription();
            } else {
              this.$router.push({ name: 'social.list' });
            }
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error, true);
            if (this.accountNotVerified(error)) {
              this.resendVerifyEmail();
            }
          });
      }
    }
  },
  mounted () {
      this.trackUserData('load_content', {
      pageType: 'register/loggin'
    });
  }
};
</script>
<style scoped>
  .relative {
    position: relative;
    width: 100%;
  }

  .absolute {
    position: absolute;
    top: 55%;
    right: 0px;
    transform: translate(-50%, -50%);
  }

  @media(max-width: 768px) {
    .absolute {
    top: 45%;
    right: 0px;
  }
  }
</style>
